import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useTranslation } from 'react-i18next'
import { useStores } from '@hooks/useStores'
import { useObserver } from "mobx-react-lite"

import SEO from "@components/Seo"
import FooterSns from "@components/blocks/global/FooterSNS"
import Footer from "@components/blocks/global/Footer"
// import Header from "@components/blocks/global/Header"
import PageHeader from "@components/blocks/common/PageHeader"
import PageSectionTitle from "@components/blocks/common/PageSectionTitle"
import PageSectionParagraph from "@components/blocks/common/PageSectionParagraph"
// import LaboList from "@components/blocks/labo/List"
import LaboList from "@components/blocks/songs/MovieList"

import OfficalWorks from "@components/blocks/index/OfficialWorks"

import css from "@css/components/blocks/common/Page.module.styl"

export default function Labo(props)
{
	const {t} = useTranslation()
    // const { appStore } = useStores()
    // const [currentLang] = useObserver(()=>[
    //     appStore.currentLang
    // ])

	const { thumbnail, labo } = props.data
    console.log('labo>', labo)

	return (
		<React.Fragment>
			<SEO title={'LABO'} />
			<main className={css.container} id="main">
				<PageHeader l1="LABO" l2={t('labo__headerTitle2')}/>
				<div className={css.content}>
					<div className={css.labo}>
						<section className={css.section}>
							{/* <PageSectionTitle l1={t('labo__sectionTitle1_1')} l2={t('labo__sectionTitle1_2')}/> */}
							{/* <PageSectionParagraph data={[t('labo__sectionParagraph1_1')]}/> */}
							<LaboList data={labo.edges}/>
						</section>
                        <section className={css.section}>
                            <PageSectionTitle l1={t('index__supportTitleOfficialWorks')} l2={t('index__officialWorksTitle2')} />
                            <OfficalWorks contentMode />
                        </section>
					</div>
				</div>
			</main>
			<FooterSns/>
			<Footer/>
		</React.Fragment>
	)
}

export const pageQuery = graphql`
    query {
        thumbnail : file(relativePath: { eq: "sample__img__thumbnail02@2x.png" }) {
            childImageSharp {
                fluid(maxWidth: 640) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        labo: allWordpressWpLabo (sort: { fields: date, order: ASC }) {
            edges {
                node {
					id
					status
                    title
                    content
                    date(formatString: "YYYY-MM-DD")
                    acf {
                        #labo_url
                        #labo_label
                        youtube_id
					}
					#featured_media {
            		#	source_url
                    #    localFile {
                    #        publicURL
                    #        childImageSharp {
                    #            fluid(maxWidth: 1280) {
                    #                ...GatsbyImageSharpFluid
                    #            }
                    #            thumbnail: fluid(maxWidth: 640) {
                    #                ...GatsbyImageSharpFluid
                    #            }
                    #        }
                    #    }
            		#}
                }
            }
        }
    }
`