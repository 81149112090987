import React from "react"
import Iframe from 'react-iframe'

import css from "@css/components/blocks/vocaloid/MovieList.module.styl"

export default function VocaloidMovieList({data, currentLang})
{
    return(
        <div className={css.container}>
            <ul className={css.inner}>
                {
                    data.map(( {node}, i)=>{
                        const { title, content, acf} = node
                        return(
                            <li className={css.item} key={i}>
                                <figure className={css.movie}>
                                    <Iframe url={`https://www.youtube.com/embed/${acf.youtube_id}`} width="640px" height="400px" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
                                </figure>
                                <div className={css.detail}>
                                    <h4 className={css.title}
                                        dangerouslySetInnerHTML={{ __html: currentLang === 'ja' ? title : acf.en_title || title}}/>
                                    <p className={css.text}
                                        dangerouslySetInnerHTML={{__html: currentLang === 'ja' ? content : acf.en_text || content}}/>
                                </div>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}
